import { Day } from "./day.class";

export class Week {

    public static noComplements = [
        new Day('week.monday', 1, false),
        new Day('week.tuesday', 2, false),
        new Day('week.wednesday', 3, false),
        new Day('week.thursday', 4, false),
        new Day('week.friday', 5, false),
        new Day('week.saturday', 6, false),
        new Day('week.sunday', 7, false)
    ];

    public static complements = [
        new Day('week.monday', 1),
        new Day('week.tuesday', 2),
        new Day('week.wednesday', 3),
        new Day('week.thursday', 4),
        new Day('week.friday', 5),
        new Day('week.saturday', 6),
        new Day('week.sunday', 7)
    ];

    public static createByArray(days)
    {
        let daysD = [];
        days.forEach((day, index) => {
            let dayD = new Day(this.getDayTranslateByKey(index), index+1, false);
            dayD.schedule.forEach(d => {
                d.array = day[d.itemName];
                
            });
            daysD.push(dayD);
        });
        
        return daysD;
    }

    private static getDayTranslateByKey(key: number) {
        switch(key) {
            case 0: return 'week.monday';
            case 1: return 'week.tuesday';
            case 2: return 'week.wednesday';
            case 3: return 'week.thursday';
            case 4: return 'week.friday';
            case 5: return 'week.saturday';
            case 6: return 'week.sunday';
        }
    }
}