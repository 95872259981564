import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CurrentFilter } from "src/app/shared/interfaces/filter.interface";
import { NutriwellService } from "src/app/shared/services/nutriwell.service";
import { environment } from "src/environments/environment";
import { IngredientRecipe, Recipe, RecipeList } from "./recipe.interface";

@Injectable({
    providedIn: 'root'
})
export class RecipeService extends NutriwellService{

    listRecipe(page: number, per_page: number, fields?: CurrentFilter[]) {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('per_page', per_page);

        if (fields) {
            fields.forEach(field => {
                params = params.append(field.name, field.value)
            });
        }

       return this.http.get(`${environment.apiUrl}/admin/recipes`, { params })
            .pipe(map((response: RecipeList[]) => {
                return response['data'];
            }));
    }

    listRecipeFilters() {
        return this.http.get(`${environment.apiUrl}/admin/recipes/filters`).pipe(
            map(res =>  {
                return res['data'];
            })
        );
    }

    getRecipeById(recipeId: string) {
        let url = `${environment.apiUrl}/admin/recipes/${recipeId}`;
        return this.http.get(url)
            .pipe(map((response: RecipeList) => {
                return response['data'];
            }));
    }

    createOrUpdateRecipe(recipe: Recipe, isNew: boolean) {
        let url = `${environment.apiUrl}/admin/recipes`;

        if(!isNew) {
            url += `/${recipe.id}`;
            return this.http.patch(url, recipe).pipe(
                map(res =>  {
                    return res;
                })
            );
        } else {
            return this.http.post(url, recipe).pipe(
                map(res =>  {
                    return res;
                })
            );
        }
    }
    
    addIngredient(recipeId: string, ingredientRecipe: IngredientRecipe) {
        let ingredient = ingredientRecipe.ingredient;
        let url = `${environment.apiUrl}/admin/recipes/${recipeId}/addIngredient`;

        const addIngredientToRecipe = {
            ingredient: {
                id: ingredient.id,
                quantity: ingredientRecipe.quantity
            }
        }
        return this.http.put(url, addIngredientToRecipe).pipe(
            map(res =>  {
                return res;
            })
        );
    }

    removeIngredient(recipeId: string, ingredientId: string) {
        let url = `${environment.apiUrl}/admin/recipes/${recipeId}/deleteIngredient/${ingredientId}`;

        return this.http.delete(url).pipe(
            map(res =>  {
                return res;
            })
        );
    }
}