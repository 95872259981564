import { Schedule } from "./schedule.class";

export class Day {

    public name: string;
    public position: number;
    public schedule: Schedule[];

    constructor(name, position, complements: boolean = true) {
        this.position = position;
        this.name = name;
        this.schedule = [
            new Schedule('Breakfast', 'breakfast'),
            new Schedule('Mid Morning', 'mid_morning'),
            new Schedule('Lunch', 'lunch'),
            new Schedule('Snack', 'snscks'),
            new Schedule('Dinner', 'dinners'),
        ];
        if(complements) {
            this.schedule.unshift(new Schedule('Complements', 'complements') );
        }
    }
}