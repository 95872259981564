export class Schedule {

    public name: string;
    public itemName: string;
    public array = [];

    constructor(name, itemName) {
        this.name = name;
        this.itemName = itemName;
    }
}